<template>
  <el-dialog
    custom-class="padd1020"
    :visible.sync="visible"
    :width="width"
    :title="title"
    :before-close="closeModal"
    :close-on-click-modal="false"
  >
    <div slot="title">
      <span class="icon-warning el-icon-warning"></span>
      <span class="title">{{ title }}</span>
    </div>
    <div v-if="data && data.length" class="content">
      <p class="tip-row" v-for="(item, index) in data" :key="index">
        <span class="tip-dot"></span>
        <span class="tip-content">{{ item }}</span>
      </p>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="closeModal" size="small">确&nbsp;&nbsp;认</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "message-tip",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [Number, String],
      default: "提示",
    },
    data: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "30%",
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal(done) {
      if (typeof done === "function") {
        done();
      }
      this.visible = false;
      // this.$el.parentNode.removeChild(this.$el);
      this.$emit("confirm", 666);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 90%;
  margin: auto;
}
.tip-row {
  margin: 8px 0;
}
.tip-row:first-of-type,
.tip-row:last-of-type {
  margin-top: 0;
}
.tip-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e0371c;
  vertical-align: middle;
}
.tip-content {
  color: #666;
  vertical-align: middle;
  margin-left: 8px;
}
.icon-warning {
  font-size: 21px;
  color: #e0371c;
  vertical-align: middle;
}
.title {
  font-size: 16px;
  margin-left: 8px;
  color: #333;
  vertical-align: middle;
}
</style>
