// 汇报管理
import menuMain from '@/components/main/mainLevelOne'

const trainReportRouter = {
	path: '/trainReport',
	component: menuMain,
	redirect: "/examSummary",
	name: 'trainReport',
	meta: {
		title: '培训报表',
	},
	children: [
		{
			path: '/examSummary',
			component: () => import('@/views/examinationCenter/examSummary/index.vue'),
			name: 'examSummary',
			meta: { title: '考试汇总', belongTo: '培训报表', belongPath: '/examSummary' }
		},
		{
			path: '/examSummaryDept/:name',
			component: () => import('@/views/examinationCenter/examSummary/components/summaryDept.vue'),
			name: 'examSummaryDept',
			meta: { title: '部门', belongTo: '考试汇总', belongPath: '/examSummary' }
		},
		{
			path: '/examSummaryCourse/:name/:title',
			component: () => import('@/views/examinationCenter/examSummary/components/summaryAllocation.vue'),
			name: 'examSummaryCourse',
			meta: { title: '考试', belongTo: '考试汇总', belongPath: '/examSummary' }
		},
		{
			path: '/examSummaryStreet/:title',
			component: () => import('@/views/examinationCenter/examSummary/components/summaryStreet.vue'),
			name: 'examSummaryStreet',
			meta: { title: '街道', belongTo: '考试汇总', belongPath: '/examSummary' },
		},
		{
			path: '/examSummaryStreet/:street/:community',
			component: () => import('@/views/examinationCenter/examSummary/components/summaryCommunity.vue'),
			name: 'examSummaryCommunity',
			meta: { title: '社区', belongTo: '考试汇总', belongPath: '/examSummary' }
		}
		,
		{
			path: '/learnSummary',
			component: () => import('@/views/curriculumCenter/courseSummary/index.vue'),
			name: 'learnSummary',
			meta: { title: '学习汇总', belongTo: '培训报表', belongPath: '/learnSummary' }
		},
		{
			path: '/summaryDept/:name',
			component: () => import('@/views/curriculumCenter/courseSummary/components/summaryDept.vue'),
			name: 'summaryDept',
			meta: { title: '部门', belongTo: '学习汇总', belongPath: '/learnSummary' }
		},
		{
			path: '/summaryCourse/:title',
			component: () => import('@/views/curriculumCenter/courseSummary/components/summaryCourse.vue'),
			name: 'summaryCourse',
			meta: { title: '课程', belongTo: '学习汇总', belongPath: '/learnSummary' }
		},
		{
			path: '/summaryStreet/:title',
			component: () => import('@/views/curriculumCenter/courseSummary/components/summaryStreet.vue'),
			name: 'summaryStreet',
			meta: { title: '街道', belongTo: '学习汇总', belongPath: '/learnSummary' },
		},
		{
			path: '/summaryStreet/:street/:community',
			component: () => import('@/views/curriculumCenter/courseSummary/components/summaryCommunity.vue'),
			name: 'summaryCommunity',
			meta: { title: '社区', belongTo: '学习汇总', belongPath: '/learnSummary' }
		}
	]
}

export default trainReportRouter
