const state = {
	activeBtn: 1
}

const mutations = {
	SET_ACTIVEBTN: (state, type) => {
		state.activeBtn = type
	},

}

const actions = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}