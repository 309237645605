// 我的
import menuMain from '@/components/main/mainLevelOne'

const dailyOfficeRouter = {
	path: '/dailyOffice',
	component: menuMain,
	name: 'dailyOffice',
	meta: { title: '我的', noCache: true },
	children: [
		{
			path: '/approval',
			name: 'approval',
			meta: { title: '审批' },
			component: () => import('@/views/dailyOffice/approval/index.vue')
		},
		{
			path: '/examination',
			name: 'examination',
			meta: { title: '考试' },
			component: () => import('@/views/dailyOffice/exam/examination.vue')
		},
		{
			path: '/examIng',
			name: 'examIng',
			meta: { title: '开始考试' },
			component: () => import('@/views/dailyOffice/exam/examIng.vue')
		},
	]
}

export default dailyOfficeRouter
