// 组织架构管理
import menuMain from '@/components/main/mainLevelOne'

const examRouter = {
	path: '/examinationCenter',
	component: menuMain,
	redirect: "/examClass",
	name: 'examinationCenter',
	meta: {
		title: '考试中心',
		icon: 'exam'
	},
	children: [
		{
			path: '/examClass',
			component: () => import('@/views/examinationCenter/ExaminationClassification.vue'),
			name: 'examClass',
			meta: {
				title: '考试分类',
			}
		},
		{
			path: '/questionManage',
			name: 'questionManage',
			meta: { title: '题库管理' },
			component: () => import('@/views/examinationCenter/QuestionManage.vue')
		},
		{
			path: '/examManage',
			name: 'examManage',
			meta: { title: '考试管理' },
			component: () => import('@/views/examinationCenter/ExaminationManage.vue')
		},
		{
			path: '/matterAdd',
			name: 'matterAdd',
			meta: { title: '新增试题', belongTo: '题库管理', belongPath: '/questionManage' },
			component: () => import('@/views/examinationCenter/MatterAdd.vue')
		},
		{
			path: '/examAdd',
			name: 'examAdd',
			meta: { title: '新增考试', belongTo: '考试管理', belongPath: '/examManage' },
			component: () => import('@/views/examinationCenter/ExamAdd.vue')
		},
		{
			path: '/scoreList',
			name: 'scoreList',
			meta: { title: '成绩', belongTo: '考试管理', belongPath: '/examManage' },
			component: () => import('@/views/examinationCenter/ScoreList.vue')
		},

		{
			path: '/scoreDetail',
			name: 'scoreDetail',
			meta: { title: '成绩详情', belongTo: '成绩', belongPath: '/scoreList' },
			component: () => import('@/views/examinationCenter/ScoreDetail.vue')
		},
		{
			path: '/examPaper',
			name: 'examPaper',
			meta: { title: '试卷', belongTo: '考试管理', belongPath: '/examManage' },
			component: () => import('@/views/examinationCenter/ExamPaper.vue')
		},
		{
			path: '/allocationExam',
			name: 'allocationExam',
			meta: { title: '分配考试', belongTo: '考试管理', belongPath: '/examManage' },
			component: () => import('@/views/examinationCenter/AllocationExam.vue')
		},
	]
}

export default examRouter
