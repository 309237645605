import { systemApi } from '@/utils/api';

export function login(data) { //手机号密码登录
  return systemApi({
    url: '/api/Sys/login',
    method: 'post',
    data
  })
}
//登录相关界面优化 start

export function getLoginSmsVerifyCode(params) { //获取登录短信验证码
  return systemApi({
    url: '/api/Sys/getLoginSmsVerifyCode',
    method: 'GET',
    params
  })
}
export function resetUserPassword(data) { // 忘记密码后直接重置密码(未登录状态）
  return systemApi({
    url: '/api/Sys/resetUserPassword',
    method: 'POST',
    data
  })
}
export function loginBySms(data) { //手机号短信验证码登录
  return systemApi({
    url: '/api/Sys/loginBySms',
    method: 'POST',
    data
  })
}

export function getResetSmsVerifyCode(params) { //获取【重置】手机号或密码短信验证码
  return systemApi({
    url: '/api/Sys/getResetSmsVerifyCode',
    method: 'GET',
    params
  })
}

export function checkResetSmsVerifyCode(data) { //验证【重置】手机号或密码短信验证码
  return systemApi({
    url: '/api/Sys/checkResetSmsVerifyCode',
    method: 'POST',
    data
  })
}

export function getLoginSysUser(params) { //获取登录用户个人信息
  return systemApi({
    url: '/api/Sys/getLoginSysUser',
    method: 'GET',
    params
  })
}

export function updateLoginSysUserBasic(data) { //修改登录用户基础信息---个人中心
  return systemApi({
    url: '/api/Sys/updateLoginSysUserBasic',
    method: 'POST',
    data
  })
}

export function updateLoginSysUserMobile(data) { //更新登录用户手机号----修改手机号--个人中心
  return systemApi({
    url: '/api/Sys/updateLoginSysUserMobile',
    method: 'POST',
    data
  })
}

export function updateLoginSysUserPwd(data) { //更新登录用户密码-----修改密码
  return systemApi({
    url: '/api/Sys/updateLoginSysUserPwd',
    method: 'POST',
    data
  })
}


// 登录相关界面优化 end

export function getPermission() { //获取登录用户可访问的功能权限列表（模块+菜单）
  return systemApi({
    url: '/api/Sys/getPermission',
    method: 'POST'
  })
}

export function getLoginUserInfo(params) { //获取当前登录用户基本信息（主要是id）
  return systemApi({
    url: '/api/Sys/getLoginUserInfo',
    method: 'GET',
    params
  })
}

export function getSysUser(params) { //获取用户
  return systemApi({
    url: '/api/Sys/getSysUser',
    method: 'GET',
    params
  })
}