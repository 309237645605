<template>
  <div class="layoutMain" style="padding: 0 16px 16px 16px; height: 100%">
    <!-- 所有二层路由指向 需要写配置的面包屑，我的地盘/状态/详情-->
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "mainLevelOne",
  components: {
    Breadcrumb,
  },
};
</script>
<style lang="scss" scoped>
.layoutMain {
  display: flex;
  flex-direction: column;
}
</style>
