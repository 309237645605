// 项目管理
import menuMain from '@/components/main/mainLevelOne'

const projectList = {
	path: '/projectManage',
	component: menuMain,
	redirect: "/projectList",
	name: 'projectManage',
	meta: {
		title: '项目管理',
	},
	children: [
		{
			path: '/projectList',
			component: () => import('@/views/projectManage/ProjectList.vue'),
			name: 'projectList',
			meta: {
				title: '项目列表',
			}
		},
		{
			path: '/projectList/projectEdit',
			name: 'projectEdit',
			meta: { title: '项目编辑', belongTo: '项目管理', belongPath: '/projectList' },
			component: () => import('@/views/projectManage/ProjectAdd.vue')
		},
		{
			path: '/projectList/projectAdd',
			name: 'projectAdd',
			meta: { title: '项目新增', belongTo: '项目管理', belongPath: '/projectList' },
			component: () => import('@/views/projectManage/ProjectAdd.vue')
		},
		{
			path: '/projectList/projectDetail',
			name: 'projectDetail',
			meta: { title: '项目详情', belongTo: '项目管理', belongPath: '/projectList' },
			component: () => import('@/views/projectManage/ProjectDetail.vue')
		},
		{
			path: '/projectArea',
			name: 'projectArea',
			meta: { title: '项目覆盖区域' },
			component: () => import('@/views/projectManage/AreaManage.vue')
		},
	]
}

export default projectList
