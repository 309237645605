<script>
// 这个是侧边栏
export default {
  name: "sideBar",
  data() {
    return {};
  },
  watch: {
    menu1Name() {
      this.$store.dispatch("permission/getSideBar");
    },
  },
  created() {
    this.$store.dispatch("permission/getSideBar");
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, name } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      if (meta.belongTo && meta.belongPath) {
        return meta.belongPath.substring(1);
      }
      return name;
    },
    menu1Name() {
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
      let firstMenu = matched[0]?.name || {};
      return firstMenu;
    },
    sideBar: {
      get() {
        const permissionArr = this.$store.state.permission.permissionArr;
        // if (permissionArr && permissionArr.length > 0) {
        //   // 针对只有一个子菜单的一级菜单扁平化
        //   for (let index = 0; index < permissionArr.length; index++) {
        //     const item = permissionArr[index];
        //     if (item.children && item.children.length === 1) {
        //       permissionArr[index] = item.children[0];
        //     }
        //   }
        // }
        return permissionArr;
      },
      set(val) {
        this.$store.dispatch("permission/permissionArr", val);
      },
    },
  },
  methods: {
    pathTo(index, path) {
      console.log("index, path :>> ", path);
      this.$router.push({ name: index });
    },
  },
  // eslint-disable-next-line no-unused-vars
  render: function (h) {
    var showMenu = menuList => {
      if (menuList && menuList.length > 0) {
        return menuList.map((menuItem, _index) => {
          if (menuItem.children && menuItem.children.length > 0) {
            return (
              <el-submenu index={menuItem.name} key={menuItem.id} title={menuItem.text}>
                <template slot="title">
                  <i class={`iconfont icon${menuItem.icon}`}></i>
                  <span>{menuItem.title}</span>
                </template>
                {showMenu(menuItem.children)}
              </el-submenu>
            );
          } else {
            return (
              <el-menu-item index={menuItem.name} key={menuItem.id}>
                <i class={`iconfont icon${menuItem.icon}`}></i>
                <span slot="title">{menuItem.title}</span>
              </el-menu-item>
            );
          }
        });
      }
    };
    return (
      <div>
        <el-scrollbar wrap-class="scrollbar-wrapper">
          <el-menu
            mode="vertical"
            unique-opened={true}
            default-active={this.activeMenu}
            background-color="#ffffff"
            text-color="#333333"
            active-text-color="#409EFF"
            collapse-transition={false}
            onselect={this.pathTo}
          >
            <template style="display: block">{showMenu(this.sideBar)}</template>
          </el-menu>
        </el-scrollbar>
      </div>
    );
  },
};
</script>
