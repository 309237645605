<template>
  <div class="navbar">
    <div class="left">
      <img src="@/assets/login/company_icon.png" class="logo" width="30" height="30" />
      <span>{{ title }}</span>
    </div>
    <!-- <firstMenu></firstMenu> -->
    <div class="right"><rightPanel></rightPanel></div>
  </div>
</template>

<script>
import getPageTitle from "@/utils/get-page-title";
import { mapGetters } from "vuex";
// import firstMenu from "./firstMenu";
import rightPanel from "./rightPanel";

export default {
  name: "NavBar",
  data() {
    return {
      title: getPageTitle(),
    };
  },
  components: {
    // firstMenu,
    rightPanel,
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {},
  created() {},
};
</script>
<style lang="less" scoped>
.navbar {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  background-color: #409eff;
  height: 100%;
  line-height: 45px;
  .left {
    width: 226px !important;
    white-space: nowrap;
  }
  .logo {
    vertical-align: middle;
    margin: -4px 10px 0 16px;
    border-radius: 100%;
  }
  .right {
    position: absolute;
    right: 16px;
  }
}
</style>
