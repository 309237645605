// 课程管理
import menuMain from '@/components/main/mainLevelOne'

const curriculumRouter = {
	path: '/curriculumCenter',
	component: menuMain,
	redirect: "/courseManage",
	name: 'curriculumCenter',
	meta: {
		title: '学习中心',
		icon: 'exam'
	},
	children: [
		{
			path: '/courseLabel',
			component: () => import('@/views/curriculumCenter/LabelManage.vue'),
			name: 'courseLabel',
			meta: {
				title: '课程标签管理',
			}
		},
		{
			path: '/courseManage',
			component: () => import('@/views/curriculumCenter/CurriculumManage.vue'),
			name: 'courseManage',
			meta: {
				title: '课件管理',
			}
		},
		{
			path: '/courseAdd',
			component: () => import('@/views/curriculumCenter/CourseAdd.vue'),
			name: 'courseAdd',
			meta: {
				title: '新增课件',
				belongTo: '课件管理',
				belongPath: '/courseManage'
			}
		},
		{
			path: '/courseEdit',
			component: () => import('@/views/curriculumCenter/CourseAdd.vue'),
			name: 'courseEdit',
			meta: {
				title: '修改课件',
				belongTo: '课件管理',
				belongPath: '/courseManage'
			}
		},
		{
			path: '/courseDetail',
			component: () => import('@/views/curriculumCenter/CourseDetail.vue'),
			name: 'courseDetail',
			meta: {
				title: '查看课件',
				belongTo: '课件管理',
				belongPath: '/courseManage'
			}
		},
		{
			path: '/courseAssign',
			component: () => import('@/views/curriculumCenter/CurriculumDistribution.vue'),
			name: 'courseAssign',
			meta: {
				title: '课程分配',
			}
		},
		{
			path: '/courseProgress',
			component: () => import('@/views/curriculumCenter/SpeedOfProgress.vue'),
			name: 'courseProgress',
			meta: {
				title: '课程进度',
			}
		},
	]
}

export default curriculumRouter
