<template>
  <el-dropdown @command="handleCommand">
    <span class="el-dropdown-link" style="color: #fff; cursor: pointer">
      <img v-if="$store.getters.avatar" :src="$UPLOAD_URL + $store.state.user.avatar" class="touixiang" height="20" width="20" />
      <img v-else src="@/assets/img/user.png" class="touixiang" height="20" width="20" />
      <span>&nbsp;{{ $store.getters.name }}</span>
      <i class="el-icon-caret-bottom"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="personnelCenter" icon="el-icon-user">个人中心<span style="margin-right: 20px"></span></el-dropdown-item>
      <el-dropdown-item command="loginOut" icon="el-icon-switch-button">安全退出<span style="margin-right: 20px"></span></el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleCommand(command) {
      if (command === "personnelCenter") {
        this.$router.push({ name: "personnelCenter" });
      } else if (command === "loginOut") {
        this.$store.dispatch("user/logout");
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.touixiang {
  vertical-align: middle;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
</style>
