// 系统管理
// import Main from '@/components/main/mainLevelTwo'
import menuMain from '@/components/main/mainLevelOne'

const chartsRouter = {
	path: '/systemManage',
	component: menuMain,
	redirect: "/userSetting",
	name: 'systemManage',
	meta: {
		title: '系统管理',
		icon: 'el-icon-star-on'
	},
	children: [
		{
			path: '/userSetting',
			component: () => import('@/views/systemManage/UserManage.vue'),
			name: 'userSetting',
			meta: {
				title: '用户管理',
			}
		},
		{
			path: '/roleInfo',
			name: 'roleInfo',
			hidden: true,
			meta: { title: '查看角色', belongTo: '角色管理', belongPath: '/roleSetting' },
			component: () => import('@/views/systemManage/RoleDetail.vue')
		},
		{
			path: '/updateroleInfo',
			name: 'updateroleInfo',
			hidden: true,
			meta: { title: '编辑角色', belongTo: '角色管理', belongPath: '/roleSetting' },
			component: () => import('@/views/systemManage/RoleDetail.vue')
		},
		{
			path: '/roleSetting',
			component: () => import('@/views/systemManage/RoleManage.vue'),
			name: 'roleSetting',
			meta: {
				title: '角色管理',
			}
		},
		{
			path: '/operationLog',
			component: () => import('@/views/systemManage/HandleLog.vue'),
			name: 'operationLog',
			meta: { title: '操作日志', }
		},
		{
			path: '/approvalSetting',
			component: () => import("@/views/systemManage/Approval/list.vue"),
			name: 'approvalSetting',
			meta: {
				title: '审批设置'
			},
		},
		{
			path: '/approvalDetail',
			component: () => import('@/views/systemManage/Approval/detail.vue'),
			name: 'approvalDetail',
			meta: { title: '编辑流程', belongTo: '审批设置', belongPath: '/approvalSetting' },
		}
	]
}

export default chartsRouter
