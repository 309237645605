// 组织架构管理
import menuMain from '@/components/main/mainLevelOne'

const organizationRouter = {
	path: '/organization',
	component: menuMain,
	redirect: "/personnelManage",
	name: 'organization',
	meta: {
		title: '组织架构',
		icon: 'el-icon-star-on'
	},
	children: [
		{
			path: '/personnelManage',
			component: () => import('@/views/organization/PersonnelMatters.vue'),
			name: 'personnelManage',
			meta: {
				title: '人事管理',
			}
		},
		{
			path: '/personnelManage/personDetail',
			component: () => import('@/views/organization/PersonDetail.vue'),
			name: 'personDetail',
			meta: {
				title: '人员详情',
				belongTo: '人事管理',
				belongPath: '/personnelManage'
			}
		},
		{
			path: '/departmentManage',
			name: 'departmentManage',
			meta: { title: '部门管理' },
			component: () => import('@/views/organization/Department.vue')
		},
		{
			path: '/jobManage',
			name: 'jobManage',
			meta: { title: '岗位管理' },
			component: () => import('@/views/organization/Post.vue')
		}
	]
}

export default organizationRouter
