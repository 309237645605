import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, _from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)

  console.log('to :>> ', to);

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {

      const hasUserInfo = store.getters.name; // 判断是否有userInfo
      if (!hasUserInfo) {
        await store.dispatch('user/getInfo')
      }
      // await store.dispatch('user/getCurrentPosition')
      const hasPermissionArr = store.getters.permissionArr && store.getters.permissionArr.length > 0;
      if (hasPermissionArr) {
        // 控制权限不能随便输入进行访问 start
        const list = store.getters.permissionArr;
        const plain = list => list.reduce((accr, item) => {
          accr.push(item)
          if (item.children && item.children.length) {
            accr = accr.concat(plain(item.children))
          }
          return accr
        }, [])

        if (plain(list).some(item => item.name === to.name)) {
          next()
        } else {

          next() //需要修改，去到没权限 to do
        }
        // 控制权限不能随便输入进行访问 end to do测试
      } else {
        try {
          // 没有权限菜单，去获取权限菜单
          store.dispatch('permission/generateRoutes').then(_data => {
            // 控制权限不能随便输入进行访问 start
            const list = _data;
            const plain = list => list.reduce((accr, item) => {
              accr.push(item)
              if (item.children && item.children.length) {
                accr = accr.concat(plain(item.children))
              }
              return accr
            }, [])

            if (plain(list).some(item => item.name === to.name)) {
              next()
            } else {
              next() //需要修改，去到没权限 to do
            }
            // 控制权限不能随便输入进行访问 end to do测试
          }).catch(_error => {
            Message.error(_error)
            next(`/login`)
          })
        } catch (error) {
          await store.dispatch('user/resetToken')
          Message.error(error || '系统错误，请重新登录')
          next(`/login`)
          NProgress.done()
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
