<template>
  <div class="123">
    <el-dialog
      :close-on-press-escape="false"
      :show-close="false"
      :modal-append-to-body="false"
      custom-class="empty-title"
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="400px"
    >
      <span slot="success"></span>
      <slot></slot>
      <div class="warper">
        <span :class="type == 'success' ? 'el-icon-success success' : 'el-icon-error error'"></span>
        <span class="text" v-if="type == 'success'">{{ text || "执行成功" }}</span>
        <span class="text" v-if="type == 'error'">{{ text || "执行错误" }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$elsize" @click="close({ cancel: true })">取 消</el-button>
        <el-button :size="$elsize" type="primary" @click="close({ cancel: false })">{{ sureBtnStr() }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "lp-execute-panel1",
  components: {},
  props: {
    timeout: {
      type: Number,
      default: 3000,
    },
    type: {
      type: String,
      default: "",
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.type === "success" && this.timerStart();
      }
    },
  },
  data() {
    return {
      visible: false,
      text: "",
      time: 0,
      timer: null,
    };
  },
  methods: {
    timerStart() {
      this.time = this.timeout;
      this.timer = setInterval(() => {
        this.time = this.time - 1000;
        if (this.time <= 0) {
          clearInterval(this.timer);
        }
      }, 1000);
      this.closeTimer = setTimeout(() => {
        this.close();
      }, this.timeout);
    },
    sureBtnStr() {
      if (!(this.type === "success")) {
        return "确 定";
      } else {
        return (this.time / 1000 && this.time >= 0 ? this.time / 1000 : "") + "秒后返回";
      }
    },
    close(obj = {}) {
      clearInterval(this.timer);
      clearTimeout(this.closeTimer);
      this.visible = false;
      this.$emit("onClose", obj);
      setTimeout(() => {
        this.$el.parentNode.removeChild(this.$el);
      }, 100);
    },
  },
  created() {
    this.time = this.timeout || 2000;
  },
};
</script>
<style lang="scss" scoped>
.warper {
  display: flex;
  align-items: center;
}
.success {
  color: #00ae66;
  font-size: 26px;
}
.error {
  color: #ea2e5d;
  font-size: 26px;
}
.text {
  font-size: 16px;
  margin-left: 8px;
}
</style>
