import ExcutePanel from './panel.vue';

export default {
  install: function (Vue, _options) {
    const Instance = Vue.extend(ExcutePanel);
    let currentPanel;
    const initInstance = () => {
      // 实例化vue实例
      currentPanel = new Instance();
      let dom = currentPanel.$mount().$el;
      document.body.appendChild(dom);

    };
    // 在Vue的原型上添加实例方法，以全局调用
    Vue.prototype.$excutePanel = (options) => {

      initInstance();

      if (typeof options === 'string') {
        currentPanel.data = [options];
      } else if (Array.isArray(options)) {
        currentPanel.data = options;
      } else if (typeof options === 'object') {
        Object.assign(currentPanel, options);
      }
      currentPanel.visible = true;
      return currentPanel; // 为了链式调用
    }
  }
}