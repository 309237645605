import Tip from './tip.vue';

// 定义插件对象
const MessageTip = {};
// install vue插件
MessageTip.install = function (Vue, _options) {
  const MessageTipInstance = Vue.extend(Tip);
  let currentMsg;
  const initInstance = () => {
    // 实例化vue实例
    currentMsg = new MessageTipInstance();
    let msgBoxEl = currentMsg.$mount().$el;
    document.body.appendChild(msgBoxEl);
  };
  // 在Vue的原型上添加实例方法，以全局调用
  Vue.prototype.$msgTip = (options, width) => {
    if (!currentMsg) {
      initInstance();
    }
    if (width) {
      currentMsg.width = width;
    }
    if (typeof options === 'string') {
      currentMsg.data = [options];
    } else if (Array.isArray(options)) {
      currentMsg.data = options;
    } else if (typeof options === 'object') {
      Object.assign(currentMsg, options);
    }
    currentMsg.visible = true;
    return currentMsg; // 为了链式调用
  }
};
export default MessageTip;