/**
 * 状态映射文件
 * 
*/



/* 流程状态映射 number -> char */
export const flowStatus = {
  0: '处理中',
  1: '已完成',
  3: '已终止'
}