

const personnelCenterRouter = {
	path: '/personnelCenter',
	component: () => import('@/views/personnelCenter/PersonnelCenter.vue'),
	name: 'personnelCenter',
	meta: {
		title: '个人中心',
	}

}

export default personnelCenterRouter
