// 公告管理
import menuMain from '@/components/main/mainLevelOne';

const noticeManageRouter = {
  path: '/infoManage',
  component: menuMain,
  redirect: '/noticeManage',
  name: 'infoManage',
  meta: {
    title: '信息管理',
  },
  children: [
    {
      path: '/noticeManage',
      component: () => import('@/views/noticeAndConsultManage/Manage.vue'),
      name: 'noticeManage',
      meta: {
        title: '公告管理',
        routeView: '公告',
      },
    },
    {
      path: '/noticeManage/noticeEdit',
      name: 'noticeEdit',
      meta: { routeView: '公告', title: '公告编辑', belongTo: '公告管理', belongPath: '/noticeManage' },
      component: () => import('@/views/noticeAndConsultManage/Add.vue'),
    },
    {
      path: '/noticeManage/noticeAdd',
      name: 'noticeAdd',
      meta: { routeView: '公告', title: '公告新增', belongTo: '公告管理', belongPath: '/noticeManage' },
      component: () => import('@/views/noticeAndConsultManage/Add.vue'),
    },
    {
      path: '/noticeManage/noticeDetail',
      name: 'noticeDetail',
      meta: { routeView: '公告', title: '公告详情', belongTo: '公告管理', belongPath: '/noticeManage' },
      component: () => import('@/views/noticeAndConsultManage/Detail.vue'),
    },
    {
      path: '/informationManage',
      component: () => import('@/views/noticeAndConsultManage/Manage.vue'),
      name: 'informationManage',
      meta: {
        title: '资讯管理',
        routeView: '资讯',
      },
    },
    {
      path: '/informationManage/informationEdit',
      name: 'informationEdit',
      meta: { routeView: '资讯', title: '资讯编辑', belongTo: '资讯管理', belongPath: '/informationManage' },
      component: () => import('@/views/noticeAndConsultManage/Add.vue'),
    },
    {
      path: '/informationManage/informationAdd',
      name: 'informationAdd',
      meta: { routeView: '资讯', title: '资讯新增', belongTo: '资讯管理', belongPath: '/informationManage' },
      component: () => import('@/views/noticeAndConsultManage/Add.vue'),
    },
    {
      path: '/informationManage/informationDetail',
      name: 'informationDetail',
      meta: { routeView: '资讯', title: '资讯详情', belongTo: '资讯管理', belongPath: '/informationManage' },
      component: () => import('@/views/noticeAndConsultManage/Detail.vue'),
    },
    {
      path: '/agreementManage',
      component: () => import('@/views/agreementManage/AgreementManage.vue'),
      name: 'agreementManage',
      meta: {
        title: '小程序协议管理',
      },
    },
    {
      path: '/agreementManage/agreementAdd',
      name: 'agreementAdd',
      meta: { title: '协议新增', belongTo: '协议管理', belongPath: '/agreementManage' },
      component: () => import('@/views/agreementManage/AgreementAdd.vue'),
    },
    {
      path: '/agreementManage/agreementEdit',
      name: 'agreementEdit',
      meta: { title: '协议修改', belongTo: '协议管理', belongPath: '/agreementManage' },
      component: () => import('@/views/agreementManage/AgreementAdd.vue'),
    },
    {
      path: '/noticeCenter',
      name: 'noticeCenter',
      meta: { title: '消息中心' },
      component: () => import('@/views/message/MessageCenter.vue'),
    },
    {
      path: '/noticeCenter/messageAdd',
      name: 'messageAdd',
      meta: { title: '新建消息', belongTo: '消息中心', belongPath: '/noticeCenter' },
      component: () => import('@/views/message/MessageEdit.vue'),
    },
    {
      path: '/noticeCenter/messageEdit',
      name: 'messageEdit',
      meta: { title: '消息修改', belongTo: '消息中心', belongPath: '/noticeCenter' },
      component: () => import('@/views/message/MessageEdit.vue'),
    },
    {
      path: '/noticeCenter/messageInfo',
      name: 'messageInfo',
      meta: { title: '消息详情', belongTo: '消息中心', belongPath: '/noticeCenter' },
      component: () => import('@/views/message/MessageEdit.vue'),
    },
    {
      path: '/feedBack',
      name: 'feedBack',
      meta: { title: '意见反馈' },
      component: () => import('@/views/feedback/feedbackManage.vue'),
    },
  ],
};

export default noticeManageRouter;
