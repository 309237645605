import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

import Layout from '@/views/layout';

/* Router Modules */
import systemManage from './modules/systemManageRouter'
import organizationRouter from './modules/organizationRouter';
import examRouter from './modules/examRouter';
import curriculumRouter from './modules/curriculumRouter';
import personnelCenterRouter from './modules/personnelCenterRouter';
import projectListRouter from './modules/projectListRouter';
import noticeManageRouter from './modules/noticeManageRouter';
import attendanceRouter from './modules/attendanceRouter';
import dailyOfficeRouter from './modules/dailyOfficeRouter';
import trainReportRouter from './modules/trainReportRouter';

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('@/views/login'),
  },
  {
    path: '/smartScreen',
    name: "smartScreen",
    component: () => import('@/views/screen/screen.vue')
  },
  {
    path: '/',
    name: 'home',
    // redirect: "/workbench",
    component: Layout,
    children: [
      systemManage,
      organizationRouter,
      examRouter,
      curriculumRouter,
      personnelCenterRouter,
      projectListRouter,
      noticeManageRouter,
      attendanceRouter,
      dailyOfficeRouter,
      trainReportRouter
    ]
  },
  {
    path: '/noAuthority',
    name: 'noAuthority',
    component: () => import('@/views/errorPage/noAuthority.vue'),
  },
  {
    path: '*',
    name: 'notFund',
    component: () => import('@/views/errorPage/404Page.vue'),
  }
]
const createRouter = new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: constantRoutes
})

export default createRouter