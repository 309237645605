import { getSysInfo, getTopSysDeptList, getSysDeptList, getTopSysJob, getSysJobList, getSysModuleList, getFlowFieldList } from '@/utils/api/system'
function sysMoudleFormat(data, checked = false, upper_id) {
  return data.map((it, _index) => {
    it.checked = it.title == '日常办公' || checked;
    it.upper_id = upper_id;
    it.children && (it.children = sysMoudleFormat(it.children, it.title == '日常办公' || checked, it.id))
    return it;
  })
}
export default {
  namespaced: true,
  state: {
    name: 'system',
    companyInfo: {},
    sysDeptList: [],
    topSysDeptList: [],
    topSysJobList: [],
    sysJobList: [],
    sysModuleList: [],
    flowDetail: {},
    flowFieldList: [],
    customFlowDetailTemplate: { // 自定义流程新增模板
      "form_field_list": [],
      "flow_type": 1,
      "form_table_name": "sys_form_b3984208-04d9-425a-b15f-52a00327e067",
      "condition_mark": 0,
      "status_mark": 1,
      "delete_mark": 0,
      "action_mark": 0,
      "api_url": null,
      "faild_api_url": null,
      "faild_delete_mark": 0,
      "flow_category": 5,
      "flow_name": "",
      "flow_icon": "flow_icon20",
      "flow_icon_color": "00C458",
      "flow_comment": "",
      "flow_schema": JSON.stringify({ "settings": { "dept_list": [], "role_list": [], "user_list": [], "range_type": "all" }, "next_node": undefined, "node_name": "发起人节点", "node_type": "start" }),
      "access_type": 0,
      "access_setting": JSON.stringify({ "dept_list": [], "role_list": [], "user_list": [], "range_type": "all" }),
      "advance_setting": JSON.stringify({ "processor_duplicate_remove": false, "processor_as_submitter_auto": false })
    }
  },
  mutations: {
    SET_COMPANY_INFO(state, data) {
      state.companyInfo = data;
    },
    SET_DEPT_LIST_LEVEL1(state, data) {
      state.topSysDeptList = data
    },
    SET_DEPT_LIST_FORM(state, data) {
      state.sysDeptList = data
    },
    SET_JOB_LIST_LEVEL1(state, data) {
      state.topSysJobList = [data]
    },
    SET_JOB_LIST_FORM(state, data) {
      state.sysJobList = data
    },
    SET_SYS_MOUDLE_LIST(state, data) {
      state.sysModuleList = sysMoudleFormat(data)
    },
    SET_FLOW_DETAIL(state, data) {
      state.flowDetail = data;
    },
    SET_FLOW_DETAIL_BY_TEMPLATE(state) {
      state.flowDetail = JSON.parse(JSON.stringify(state.customFlowDetailTemplate));
      state.flowFieldList = [];
    },
    SET_FLOW_FORM_LIST(state, data) {
      state.flowDetail.form_field_list = data;
    },
    SET_FLOW_FIELD_LIST_AC(state, data, _fromAction) {
      state.flowFieldList = data.map(it => {
        return {
          ...it,
          field_required: it.field_required ? true : false,
          field_date_type: it.field_type,
          field_type: ['date', 'month', 'year'].includes(it.field_type) ? 'date' : it.field_type
        }
      });
    },
    SET_FLOW_FIELD_LIST(state, data, _fromAction) {
      state.flowFieldList = data.map(it => {
        return {
          ...it,
          field_required: it.field_required ? true : false
        }
      });
    }
  },
  actions: {
    /* 企业信息 */
    async getSysInfo({ commit }) {
      await getSysInfo().then(res => {
        commit('SET_COMPANY_INFO', res.data || {})
      })
    },
    /* 获取部门，用于系统管理显示部门列表 */
    async getTopSysDeptList({ commit }) {
      await getTopSysDeptList().then(res => {
        commit('SET_DEPT_LIST_LEVEL1', res.data || [])
      })
    },
    /* 表单提交时使用的部门 */
    async getSysDeptList({ commit }) {
      await getSysDeptList().then(res => {
        commit('SET_DEPT_LIST_FORM', res.data || [])
      })
    },
    /* 岗位列表 */
    async getTopSysJob({ commit }) {
      await getTopSysJob().then(res => {
        commit('SET_JOB_LIST_LEVEL1', res.data || [])
      })
    },
    /* 用于表单的岗位列表 */
    async getSysJobList({ commit }) {
      await getSysJobList().then(res => {
        commit('SET_JOB_LIST_FORM', res.data || [])
      })
    },
    /* 获取系统支持的权限全集（模块+菜单） */
    async getSysModuleList({ commit }) {
      await getSysModuleList().then(res => {
        commit('SET_SYS_MOUDLE_LIST', res.data || [])
      })
    },
    /* 获取流程的字段列表（系统内置流程仅包含条件节点字段和摘要字段） */
    async getFlowFieldList({ commit }, id) {
      await getFlowFieldList(id).then(res => {
        commit('SET_FLOW_FIELD_LIST_AC', res.data || [])
      })
    }
  }
}