<template>
  <div class="app-wrapper">
    <div class="top_menu">
      <NavBar />
    </div>
    <div style="padding-top: 45px">
      <sideBar class="sidebar-container" />
      <div class="app_content" id="appContent">
        <AppMain />
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, NavBar, sideBar } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";

export default {
  name: "ui-layout",
  components: {
    AppMain,
    NavBar,
    sideBar,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({}),
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.top_menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 45px;
  z-index: 200;
}
.app_content {
  width: calc(100vw - 236px);
  height: calc(100vh - 65px);
  overflow: auto;
  margin-left: 226px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 4px;
}
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
