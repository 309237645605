import { systemApi } from '@/utils/api';

/* 获取企业信息 */
export function getSysInfo() {
  return systemApi({
    url: '/api/Sys/getCompanyInfo',
    method: 'GET'
  })
}
/* 更新企业信息 */
export function updateSysInfo({ data, url }) {
  return systemApi({
    url: `/api/Sys/${url}`,
    method: 'POST',
    data
  })
}
/* 查询特定枚举项列表(行业类型、人员规模) */
export function getEnumList(enum_type) {
  return systemApi({
    url: `/api/Public/getEnumList`,
    method: 'GET',
    params: {
      enum_type
    }
  })
}
/* 省市区 */
export function getAddressList({
  type,
  params
}) {
  const urlMap = ['getProvinceList', 'getCityList', 'getDistrictList']
  const paramsMap = ['', "province_id", "city_id"]
  return systemApi({
    url: `/api/Public/${urlMap[type]}`,
    method: 'GET',
    params: {
      [paramsMap[type]]: params[paramsMap[type]]
    }
  })
}

/**
 ********* 部门部分
*/
/* 获取一级部门列表（每个一级部门是一棵树），用于系统管理显示部门列表 */
export function getTopSysDeptList() {
  return systemApi({
    url: '/api/Sys/getTopSysDeptList',
    method: 'GET'
  })
}


/* 获取扁平化部门列表（子部门显示为 /一级部门/二级部门/三级部门），用于表单选择部门 */
export function getSysDeptList() {
  return systemApi({
    url: '/api/Sys/getSysDeptList',
    method: 'GET'
  })
}

/* 新增部门 */
export function addSysDept(data) {
  return systemApi({
    url: '/api/Sys/addSysDept',
    method: 'POST',
    data
  })
}

export function getTableSearchFieldList(params) { // 获取特定列表的高级搜索字段列表
  return systemApi({
    url: '/api/Sys/getTableSearchFieldList',
    method: 'GET',
    params
  })
}
export function getTableExportFieldList(params) { // 获取特定列表的导出字段列表
  return systemApi({
    url: '/api/Sys/getTableExportFieldList',
    method: 'GET',
    params
  })
}
/* 编辑部门 */
export function updateSysDept(data) {
  return systemApi({
    url: '/api/Sys/updateSysDept',
    method: 'POST',
    data
  })
}

/* 删除部门 */
export function deleteSysDept(dept_id) {
  return systemApi({
    url: '/api/Sys/deleteSysDept',
    method: 'POST',
    params: {
      dept_id
    }
  })
}

/**
 * 岗位部分
*/
/* 获取岗位树（岗位只有1个一级岗位），用于系统管理显示岗位树 */
export function getTopSysJob() {
  return systemApi({
    url: '/api/Sys/getTopSysJob',
    method: 'GET'
  })
}


/* 获取扁平化岗位列表（子岗位显示为 /一级岗位/二级岗位/三级岗位），用于表单选择岗位 */
export function getSysJobList() {
  return systemApi({
    url: '/api/Sys/getSysJobList',
    method: 'GET'
  })
}

/* 新增岗位 */
export function addSysJob(data) {
  return systemApi({
    url: '/api/Sys/addSysJob',
    method: 'POST',
    data
  })
}

/* 编辑岗位 */
export function updateSysJob(data) {
  return systemApi({
    url: '/api/Sys/updateSysJob',
    method: 'POST',
    data
  })
}

/* 删除岗位 */
export function deleteSysJob(job_id) {
  return systemApi({
    url: '/api/Sys/deleteSysJob',
    method: 'POST',
    params: {
      job_id
    }
  })
}


/**
 ********* 用户设置部分
*/

/* 分页获取用户列表 */
export function getSysUserList(data) {
  return systemApi({
    url: '/api/Sys/getSysUserList',
    method: 'POST',
    data
  })
}
/* 分页获取用户列表 无禁用用户 */
export function getSearchSysUserList(data) {
  return systemApi({
    url: '/api/Sys/getSearchSysUserList',
    method: 'POST',
    data
  })
}

/* 禁用启用用户 */
export function enableSysUser({
  user_id,
  enable
}) {
  return systemApi({
    url: `/api/Sys/${enable ? 'enableSysUser' : 'disableSysUser'}`,
    method: 'POST',
    params: {
      user_id
    }
  })
}
/* 用户调整角色 */
export function updateSysUser(data) {
  return systemApi({
    url: `/api/Sys/updateSysUser`,
    method: 'POST',
    data
  })
}
/* 用户调整角色 */
export function updateSysUserRole(data) {
  return systemApi({
    url: `/api/Sys/updateSysUserRole`,
    method: 'POST',
    data
  })
}

/**
 ********* 角色设置部分
*/

/* 获取角色列表 */
export function getSysRoleList() {
  return systemApi({
    url: `/api/Sys/getSysRoleList`,
    method: 'GET',
  })
}

/* 新增角色 */
export function addSysRole(data) {
  return systemApi({
    url: `/api/Sys/addSysRole`,
    unShowErrorTip: true,
    method: 'POST',
    data
  })
}

/* 获取角色 */
export function getSysRole(role_id) {
  return systemApi({
    url: `/api/Sys/getSysRole`,
    method: 'GET',
    params: {
      role_id
    }
  })
}

/* 修改角色 */
export function updateSysRole(data) {
  return systemApi({
    url: `/api/Sys/updateSysRole`,
    method: 'POST',
    unShowErrorTip: true,
    data
  })
}

/* 删除角色 */
export function deleteSysRole(role_id) {
  return systemApi({
    url: `/api/Sys/deleteSysRole`,
    method: 'POST',
    params: {
      role_id
    }

  })
}

/* 获取模块列表 */
export function getSysModuleList() {
  return systemApi({
    url: `/api/Sys/getSysModuleList`,
    method: 'POST',
  })
}


/**
 ********* 操作日志部分
*/

export function getOperationLogList(data) {
  return systemApi({
    url: `/api/Sys/getOperationLogList`,
    method: 'POST',
    data
  })
}

/**
 ********* 流程部分
*/

export function getFlowList(_data) {
  return systemApi({
    url: `/api/Flow/getFlowList`,
    method: 'GET',
  })
}

/* 自定义流程列表 */
export function getCustomFlowList() {
  return systemApi({
    url: `/api/Flow/getCustomFlowList`,
    method: 'GET',
  })
}

/* 提交自定义流程表单 */
export function submitFormInstance() {
  return systemApi({
    url: `/api/Flow/submitFormInstance`,
    method: 'GET',
  })
}

/* 自定义流程表单记录 */
export function getCustomForm(params) {
  return systemApi({
    url: `/api/Flow/getCustomForm`,
    method: 'GET',
    params
  })
}

/* 启用/停用流程 */
export function setFlowEnable({ enable, flow_id }) {
  return systemApi({
    url: `/api/Flow/${enable ? 'enableFlow' : 'disableFlow'}`,
    method: 'POST',
    params: {
      flow_id
    }
  })
}

export function getFlowInfo(flow_id) {
  return systemApi({
    url: `/api/Flow/getFlow`,
    method: 'GET',
    params: {
      flow_id
    }
  })
}

/* 新增自定义流程 */
export function addCustomFlow(data) {
  return systemApi({
    url: `/api/Flow/addCustomFlow`,
    method: 'POST',
    data
  })
}

/* 更新自定义流程 */
export function updateCustomFlow(data) {
  return systemApi({
    url: `/api/Flow/updateCustomFlow`,
    method: 'POST',
    data
  })
}
/* 删除自定义流程 */
export function deleteCustomFlow(flow_id) {
  return systemApi({
    url: `/api/Flow/deleteCustomFlow`,
    method: 'GET',
    params: {
      flow_id
    }
  })
}

/* 更新系统流程 */
export function updateSystemFlow(data) {
  return systemApi({
    url: `/api/Flow/updateSystemFlow`,
    method: 'POST',
    data
  })
}

/* 对设置了条件的流程，提交条件节点填报内容，返回流程实例节点列表 */
export function getFlowConditionalNodes(data) {
  return systemApi({
    url: `/api/Flow/getFlowConditionalNodes`,
    method: 'POST',
    data
  })
}

/* 获取流程的字段列表（系统内置流程仅包含条件节点字段和摘要字段） */
export function getFlowFieldList(flow_id) {
  return systemApi({
    url: `/api/Flow/getFlowFieldList`,
    method: 'GET',
    params: {
      flow_id
    }
  })
}

/* 待我处理流程列表 */
export function getTodoFlowInstanceList(data) {
  return systemApi({
    url: `/api/Flow/getTodoFlowInstanceList`,
    method: 'POST',
    data
  })
}

/* 我已处理流程列表 */
export function getDoneFlowInstanceList(data) {
  return systemApi({
    url: `/api/Flow/getDoneFlowInstanceList`,
    method: 'POST',
    data
  })
}

/* 我发起的流程列表 */
export function getAppliedFlowInstanceList(data) {
  return systemApi({
    url: `/api/Flow/getAppliedFlowInstanceList`,
    method: 'POST',
    data
  })
}

/* 抄送我的的流程列表 */
export function getCcFlowInstanceList(data) {
  return systemApi({
    url: `/api/Flow/getCcFlowInstanceList`,
    method: 'POST',
    data
  })
}
/**
 * 
 * 流程部分-start
 * 
*/

/* 发起流程实例 */
export function submitFlowInstance(data) {
  return systemApi({
    url: '/api/Flow/submitFlowInstance',
    method: 'POST',
    data
  })
}

/* 重新发起流程实例 */
export function resubmitFlowInstance(data) {
  return systemApi({
    url: '/api/Flow/resubmitFlowInstance',
    method: 'POST',
    unShowErrorTip: true,
    data
  })
}


/* 获取特定的流程实例动作列表 */
export function getFlowInstanceActionList(instance_id) {
  return systemApi({
    url: '/api/Flow/getFlowInstanceActionList',
    method: 'GET',
    params: {
      instance_id
    }
  })
}

/* 将当前流程实例中与当前用户相关的抄送节点的action全部设置为已读 */
export function readFlowInstanceCcNode(flow_instance_id) {
  return systemApi({
    url: '/api/Flow/readFlowInstanceCcNode',
    method: 'POST',
    params: {
      flow_instance_id
    }
  })
}
/* 将所有流程实例与当前用户相关的抄送节点的action全部设置为已读 */
export function readFlowCcNode() {
  return systemApi({
    url: '/api/Flow/readFlowCcNode',
    method: 'POST',
  })
}

/* 获取特定的流程实例详情（不含表单和动作列表） */
export function getFlowInstanceDetail(instance_id) {
  return systemApi({
    url: '/api/Flow/getFlowInstanceDetail',
    method: 'GET',
    params: {
      instance_id
    }
  })
}

/* 执行流程实例动作 */
export function executeFlowInstanceAction(data) {
  return systemApi({
    url: '/api/Flow/executeFlowInstanceAction',
    method: 'POST',
    data
  })
}

/**
 * 
 * 流程部分-end
 * 
*/






/**
 * 
 * 辅助API
*/
/* 获取用户对象ById */
export function getSysUserListByIdList(data = []) {
  return systemApi({
    url: `/api/Sys/getSysUserListByIdList`,
    method: 'POST',
    data
  })
}

/* 获取角色对象ById */
export function getSysRoleListByIdList(data) {
  return systemApi({
    url: `/api/Sys/getSysRoleListByIdList`,
    method: 'POST',
    data
  })
}

/* 获取部门对象ById */
export function getSysDeptListByIdList(data) {
  return systemApi({
    url: `/api/Sys/getSysDeptListByIdList`,
    method: 'POST',
    data
  })
}


export function getTargetOperationLogList(data) { // 获取特定对象的操作记录列表
  return systemApi({
    url: '//api/Sys/getTargetOperationLogList',
    method: 'POST',
    data
  })
}

export function getTargetFlowInstanceList(params) { // 审批
  return systemApi({
    url: '//api/Flow/getTargetFlowInstanceList',
    method: 'GET',
    params
  })
}

export function getCustomFormList(data) { // 获取自定义流程的表单数据（通过审批已生效的）
  return systemApi({
    url: '//api/Flow/getCustomFormList',
    method: 'POST',
    data
  })
}
export function getCustomFormSearchFieldList(flow_id) { // 获取自定义流程的表单数据（通过审批已生效的）
  return systemApi({
    url: '//api/Flow/getCustomFormSearchFieldList',
    method: 'GET',
    params: {
      flow_id
    }
  })
}
export function exportCustomFormList(data) {
  return systemApi({
    url: '//api/Flow/exportCustomFormList',
    method: 'POST',
    data,
    responseType: 'blob'
  })
}

/* 获取当前流程实例可以退回的节点（含处理人）列表 */
export function getFlowInstanceReturnList(flow_instance_id) {
  return systemApi({
    url: `/api/Flow/getFlowInstanceReturnList`,
    method: 'GET',
    params: {
      flow_instance_id
    }
  })
}

