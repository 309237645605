// 考勤管理
import menuMain from '@/components/main/mainLevelOne'

const attendanceRouter = {
	path: '/attendanceManage',
	component: menuMain,
	name: 'attendanceManage',
	redirect: "/attendanceSetting",
	meta: { title: '考勤管理', noCache: true },
	children: [
		{
			path: '/attendanceSetting',
			name: 'attendanceSetting',
			meta: { title: '考勤设置' },
			component: () => import('@/views/organization/attendanceManage/attendanceSetting.vue')
		},
		{
			path: '/addCheckGroup',
			name: 'addCheckGroup',
			meta: { title: '新增考勤组' },
			component: () => import('@/views/organization/attendanceManage/addCheckGroup.vue')
		},
		{
			path: '/updateCheckGroup',
			name: 'updateCheckGroup',
			meta: { title: '编辑考勤组' },
			component: () => import('@/views/organization/attendanceManage/updateCheckGroup.vue')
		},
		{
			path: '/addVacation',
			name: 'addVacation',
			meta: { title: '新增假期' },
			component: () => import('@/views/organization/attendanceManage/addVacation.vue')
		},
		{
			path: '/updateVacation',
			name: 'updateVacation',
			meta: { title: '编辑假期' },
			component: () => import('@/views/organization/attendanceManage/updateVacation.vue')
		},
		{
			path: '/vacationRemaining',
			name: 'vacationRemaining',
			meta: { title: '假期余额' },
			component: () => import('@/views/organization/attendanceManage/vacationRemaining.vue')
		}
		,
		{
			path: '/attendanceRecord',
			name: 'attendanceRecord',
			meta: { title: '考勤记录' },
			component: () => import('@/views/organization/attendanceManage/attendanceRecord.vue')
		},
		{
			path: '/attendanceStatistics',
			name: 'attendanceStatistics',
			meta: { title: '考勤统计' },
			component: () => import('@/views/organization/attendanceManage/attendanceStatistics.vue')
		},
		{
			path: '/attendanceDetail',
			name: 'attendanceDetail',
			meta: { title: '详情', belongTo: '考勤统计' },
			component: () => import('@/views/organization/attendanceManage/attendanceDetail.vue')
		}
	]
}

export default attendanceRouter
